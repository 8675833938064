<template>
  <div class="px-4 py-1 flex flex-wrap 2xl:flex-nowrap gap-x-5 border border-gray-200 dark:border-gray-700 dark:hover:border-gray-600 rounded shadow hover:shadow-inner cursor-default">
    <div class="order-1 w-4/5 -mr-5 2xl:mr-0 2xl:w-64 overflow-x-hidden">
      <router-link :to="{ name: 'Employee', params: { id: payment.employee_id }}" class="tw--link">{{ payment.employee_name }}</router-link>
    </div>
    <div class="order-3 w-full lg:w-24 text-left 2xl:text-right font-mono font-semibold" :class="{'text-red-600 dark:text-red-700': Number(payment.amount) < 0}">
      {{ payment.amount | moneyPL }}
    </div>
    <div class="order-4 w-full sm:w-32 font-mono italic">
      {{ payment.payment_date }}
    </div>
    <div class="order-last 2xl:order-5 w-full 2xl:w-auto flex-grow font-semibold">
      {{ payment.description }}
    </div>
    <div class="order-6 w-14 flex gap-x-1 items-center" :class="{'text-muted opacity-70': !paymentIsReccuring}">
      <FontAwesomeIcon icon="redo" size="sm" />
      {{ paymentIsReccuring ? 'tak' : 'nie' }}
    </div>
    <div class="order-7 w-32 flex items-center justify-center">
      <span v-if="!paymentIsReccuring"></span>
      <span v-else-if="paymentRepeated" class="text-xs leading-3 text-muted italic">ponowione</span>
      <div v-else-if="payment.cycle_end_date" class="flex gap-x-1 items-center">
        <FontAwesomeIcon icon="step-forward" size="sm" />
        <span class="font-mono">{{ payment.cycle_end_date }}</span>
      </div>
      <span v-else class="text-xs leading-3 text-muted italic">brak daty zakończenia</span>
    </div>
    <div class="order-2 2xl:order-last w-1/5 2xl:w-14 flex gap-x-1 justify-end">
      <button @click="$emit('edit', payment)" class="tw--btn-icon" :disabled="!actionAllow" :title="actionAllow ? 'edytuj' : 'rozliczenie z przeszłości - brak możliwości edycji'"><FontAwesomeIcon :icon="['far', 'edit']" size="lg" /></button>
      <button @click="$emit('delete', payment)" class="tw--btn-icon tw--btn-icon-danger" :disabled="!actionAllow" :title="actionAllow ? 'usuń' : 'rozliczenie z przeszłości - brak możliwości usunięcia'"><FontAwesomeIcon :icon="['far', 'trash-alt']" size="lg" /></button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ExtraPaymentsListItem',
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    paymentIsReccuring() {
      return Number(this.payment.is_recurring) === 1;
    },
    paymentRepeated() {
      return Number(this.payment.repeated) === 1;
    },
    actionAllow() {
      return moment().format('MM') <= moment(this.payment.payment_date).format('MM');
    },
  },
};
</script>

<style>

</style>