<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    size="sm"
  >
    <template #header>
      {{ action === 'add' ? 'dodawanie' : 'edycja' }} rozliczenia
    </template>
    <template #default>
      <form @submit.prevent="save" class="flex flex-col gap-y-3">
        <BaseSelectWithAddon
          class="w-full"
          v-model="form.employee_id"
          labelTop="pracownik"
          :options="employeeSimpleList"
          :nullOption="false"
          :error="saveErrors.employee_id !== undefined ? saveErrors.employee_id : ''"
        />
        <BaseInputWithAddon
          type="text"
          v-model="form.description"
          labelTop="opis"
          :error="saveErrors.description !== undefined ? saveErrors.description : ''"
        />

        <div class="flex flex-wrap sm:flex-nowrap gap-4">
          <BaseInputWithAddon
            class="flex-grow flex-shrink"
            type="text"
            v-model="form.amount"
            labelTop="kwota"
            inputAlign="right"
            :error="saveErrors.amount !== undefined ? saveErrors.amount : ''"
          />
          <BaseInputWithAddon
            class="flex-shrink-0 w-full sm:w-2/3 lg:w-3/5"
            type="date"
            v-model="form.payment_date"
            labelTop="data obowiązywania"
            :error="saveErrors.payment_date !== undefined ? saveErrors.payment_date : ''"
          />
        </div>
        <div id="company-main-address-city" class="flex flex-wrap sm:flex-nowrap gap-4">
          <div class="flex-grow flex flex-col">
            <div class="text-sm text-transparent">cykliczne</div>
            <label for="is-recurring">
              <input
                id="is-recurring"
                type="checkbox"
                true-value="1" false-value="0"
                v-model="form.is_recurring"
                @change="form.cycle_end_date = ''"
              > cykliczne
            </label>
          </div>
          <BaseInputWithAddon
            class="flex-shrink-0 w-full sm:w-2/3 lg:w-3/5"
            type="date"
            v-model="form.cycle_end_date"
            labelTop="koniec cyklu"
            :disabled="form.is_recurring == 0"
            :error="saveErrors.cycle_end_date !== undefined ? saveErrors.cycle_end_date : ''"
          />
        </div>
      </form>
    </template>
  </BaseModal>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import ExtraPaymentsService from '../../services/ExtraPaymentsService';

export default {
  name: 'ExtraPaymentAddEdit',
  mixins: [errorHandler],
  props: {
    payment: {
      type: Object,
      required: true,
    },
    action: {
      validator: function(value) {
        return ['add', 'edit'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      form: {
        amount: '',
        payment_date: moment().format('YYYY-MM-DD'),
        cycle_end_date: '',
        description: '',
        employee_id: '',
        is_recurring: '0',
      },
      saveErrors: {},
    };
  },
  computed: {
    ...mapState('employees', [
      'employeeSimpleList',
    ]),
  },
  mounted() {
    if (this.action === 'edit') {
      this.form.amount = this.payment.amount.replace('.', ',');
      this.form.payment_date = this.payment.payment_date;
      this.form.cycle_end_date = this.payment.cycle_end_date;
      this.form.description = this.payment.description;
      this.form.employee_id = this.payment.employee_id;
      this.form.is_recurring = this.payment.is_recurring;
    }
  },
  methods: {
    save() {
      this.$store.dispatch('setLoadingData', true);
      this.saveErrors = {};
      this.action === 'edit' ? this.update() : this.add();
    },
    add() {
      ExtraPaymentsService.add(this.form)
        .then(response => {
          this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
          this.$emit('created', response.data.payment);
          this.$store.dispatch('setLoadingData', false);
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    update() {
      ExtraPaymentsService.update(this.payment.id, this.form)
        .then(response => {
          this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
          this.$emit('updated', response.data.payment);
          this.$store.dispatch('setLoadingData', false);
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
  },
};
</script>
