<template>
  <div id="extra-payments" class="flex flex-col gap-y-2">

    <ExtraPaymentAddEdit
      v-if="paymentModal"
      :payment="paymentToEdit"
      :action="paymentAction"
      @created="paymentCreated"
      @updated="paymentUpdated"
      @close="cancelPayment()"
    />

    <div id="sales-report-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="order-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">
              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                @input="debounceReport()"
                type="date"
                v-model="activeFilters.date_from"
                labelAddon="Od"
                :markDirty="true"
                :error="saveErrors.date_from"
              />

              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                @input="debounceReport()"
                type="date"
                v-model="activeFilters.date_to"
                labelAddon="Do"
                :markDirty="true"
                :error="saveErrors.date_to"
              />

              <div class="hidden sm:flex w-full md:min-w-max flex-1 items-center">
                <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-left" size="lg" />
                </button>
                <button @click="setToday" class="tw--btn tw--btn-secondary">aktualny mc.</button>
                <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-right" size="lg" />
                </button>
              </div>

            </div>

            <div class="flex flex-wrap items-center gap-3">
              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @input="debounceReport()"
                v-model="activeFilters.employee_id"
                label="Agent"
                :options="employeeSimpleList"
                nullOptionName="wszyscy"
              />
            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex-grow sm:hidden flex items-center">
            <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-left" size="lg" />
            </button>
            <button @click="setToday" class="tw--btn tw--btn-secondary">aktualny mc.</button>
            <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-right" size="lg" />
            </button>
          </div>
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="init" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
      <hr>
      <div class="flex flex-wrap gap-x-3 gap-y-1">
        <button @click="addPayment" class="tw--btn"><FontAwesomeIcon icon="plus" /> Nowa pozycja</button>
      </div>
      <div class="flex flex-col gap-y-1">

        <div id="extra-payments-table-header" class="px-4 -mb-1 hidden 2xl:flex gap-x-5 cursor-default" :class="{'border-b border-gray-200 dark:border-gray-700': payments.length === 0}">
          <div class="w-64">pracownik</div>
          <div class="w-24 text-right">kwota</div>
          <div class="w-32">mc. obciążenia</div>
          <div class="flex-grow">opis</div>
          <div class="w-14" title="wydarzenie cykliczne">cykl</div>
          <div class="w-32">koniec cyklu</div>
          <div class="w-14"></div>
        </div>

        <div class="relative flex flex-col gap-1">
          <DivLoadingSpinner v-if="saving" class="-mx-3 -mb-3 rounded-lg" />

          <div v-if="payments.length === 0" class="p-4 pb-0 text-lg text-muted italic opacity-70">
            brak danych z wybranego zakresu
          </div>

          <ExtraPaymentsListItem v-for="payment in payments" :key="payment.id" @edit="editPayment" @delete="delPayment" :payment="payment" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import moment from 'moment';
import ExtraPaymentsService from '../../services/ExtraPaymentsService';
import ExtraPaymentsListItem from './ExtraPaymentsListItem.vue';
import ExtraPaymentAddEdit from './ExtraPaymentAddEdit.vue';

export default {
  name: 'ExtraPaymentsList',
  components: {
    ExtraPaymentsListItem,
    ExtraPaymentAddEdit,
  },
  mixins: [errorHandler, confirm],
  data() {
    return {
      activeFilters: {
        employee_id: '',
        date_from: '',
        date_to: '',
      },
      payments: [],
      paymentToEdit: {},
      paymentAction: '',
      paymentModal: false,
      saveErrors: {},
      saving: false,
    };
  },
  computed: {
    ...mapState('employees', [
      'employeeSimpleList',
    ]),
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
    this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
    this.init();
  },
  methods: {
    init() {
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      this.getReport();
    },
    debounceReport() {
      this.saving = true;
      this.debounceReportA();
    },
    debounceReportA: debounce(function() {
      this.getReport();
    }, 600),
    getReport() {
      this.saving = true;
      // this.$store.dispatch('setLoadingData', true);
      this.saveErrors = {};
      ExtraPaymentsService.get(this.activeFilters)
        .then(response => {
          this.payments = response.data.payments;
          this.saving = false;
          // this.$store.dispatch('setLoadingData', false);
          this.setUrl();
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.saving = false;
          // this.$store.dispatch('setLoadingData', false);
        });
    },
    addPayment() {
      this.paymentToEdit = {};
      this.paymentAction = 'add';
      this.paymentModal = true;
    },
    editPayment(payment) {
      this.paymentToEdit = payment;
      this.paymentAction = 'edit';
      this.paymentModal = true;
    },
    cancelPayment() {
      this.paymentToEdit = {};
      this.paymentModal = false;
    },
    delPayment(payment) {
      this.confirm(() => this.delConfirmed(payment), 'Potwierdź usunięcie pozycji.');
    },
    delConfirmed(payment) {
      this.$store.dispatch('setLoadingData', true);
      ExtraPaymentsService.destroy(payment.id)
        .then(() => {
          this.payments = this.payments.filter(obj => obj.id !== payment.id);
          this.$store.dispatch('setLoadingData', false);
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    paymentCreated() {
      this.getReport();
      this.paymentModal = false;
    },
    paymentUpdated(payment) {
      Object.assign(this.paymentToEdit, payment);
      this.paymentModal = false;
      this.paymentToEdit = {};
    },
    prevMonth() {
      this.activeFilters.date_from = moment(this.activeFilters.date_from).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment(this.activeFilters.date_from).endOf('month').format('YYYY-MM-DD');
      this.debounceReport();
    },
    setToday() {
      this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
      this.debounceReport();
    },
    nextMonth() {
      this.activeFilters.date_from = moment(this.activeFilters.date_from).add(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment(this.activeFilters.date_from).endOf('month').format('YYYY-MM-DD');
      this.debounceReport();
    },
    setUrl() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = encodeURI(this.activeFilters[key]);
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.activeFilters)) {
        this.$router.replace({ name: 'ExtraPayments', query: this.activeFilters });
      }
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
      this.getReport();
    },
  },
};
</script>
