import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  get(params) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/extra-payments', { params })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  add(fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/extra-payments', fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  update(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.put(`/api/extra-payments/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  destroy(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/extra-payments/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};